export const domainDetails = {
  chargemiles: {
    palette: {
      primary: {
        light: "#757ce8",
        main: "#01B763",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    clusterSvgName: "chargemilesClusterSvg",
    faviconUrl: "chargemilesFavicon.ico",
    termsAndConditionUrl: "https://www.chargemiles.ca/terms-of-service",
    privacyPolicyUrl: "https://www.chargemiles.ca/privacy-policy",
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=com.ca.chargemiles",
    appStoreUrl: "https://apps.apple.com/app/chargemiles-ca/id6477575081",
    title: "ChargeMiles",
    provider: "ChargeMiles",
    domain: "chargemiles",
    logoUrl: "logo/chargemiles.jpeg",
    email: "support@chargemiles.ca",
    phone: "8037805550",
    registeredOffice:
      "Plot N0-79, Street No-9, Railway Road Samaipur Delhi 110042",
    regionalOffice:
      "C2/86, Vibhuti Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    websiteUrl: "https://www.chargemiles.ca",
    countryCode: "+1",
    google_client_id:
      "267074556499-q1mlehneuc4p91djl24jd2th0qe948tr.apps.googleusercontent.com",
    recaptcha_client: "6LcWLDEqAAAAAP0IurfoZC7a17v6PtAbbFPztRpv",
  },
  moveonev: {
    palette: {
      primary: {
        light: "#757ce8",
        main: "#dc3545",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    clusterSvgName: "moveonevClusterSvg",
    logoUrl: "logo/moveon.png",
    faviconUrl: "moveonevfavicon.ico",
    termsAndConditionUrl: "https://moveonev.com/terms-of-service",
    privacyPolicyUrl: "https://moveonev.com/privacy-policy",
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=com.moveonev.in",
    appStoreUrl: "https://apps.apple.com/app/moveonev/id6478542998",
    email: "info@moveonev.com",
    phone: "8037805550",
    whatsappContact: "8130017399",
    registeredOffice:
      "Plot N0-79, Street No-9, Railway Road Samaipur Delhi 110042",
    regionalOffice:
      "S-9 Transport Nagar Near- RTO Office Gate No - 3 Kanpur Road Lucknow. 226012",
    websiteUrl: "https://www.moveonev.com",
    title: "MoveonEV",
    domain: "moveonev",
    provider: "MoveonEV",
    countryCode: "+91",
    google_client_id:
      "37870600919-dkj1ifl2h3qoe1u51400hmu6fr62uhc6.apps.googleusercontent.com",

    recaptcha_client: "6LeeKzEqAAAAAPkbDVS8_IUAh36yUCzgQ3We2ICm",
  },

  amikcharge: {
    palette: {
      primary: {
        light: "#757ce8",
        main: "#007fff",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    clusterSvgName: "amikchargeClusterSvg",
    faviconUrl: "amikchargefavicon.ico",
    termsAndConditionUrl: "https://amiktechnologies.com/terms-of-service",
    privacyPolicyUrl: "https://amiktechnologies.com/privacy-policy",
    playStoreUrl: "https://play.google.com/store/apps/details?id=com.in.amik",
    appStoreUrl: "https://apps.apple.com/in/app/amik-charge/id6482231255",
    title: "AmikTechnologies",
    domain: "amiktechnologies",
    provider: "AMIK Technologies",
    logoUrl: "logo/amikCharge.jpg",
    email: "info@amiktechnologies.com",
    phone: "8037805550",
    whatsappContact: "8130017399",
    registeredOffice:
      "A9-405, Casa Greens 1, Sector 16, Greater Noida West, 201318",
    regionalOffice:
      "Floor 24, Tower B, Bhutani Alphatum, Sector 90, Noida, Uttar Pradesh 201305",
    websiteUrl: "https://www.amiktechnologies.com",
    countryCode: "+91",
    google_client_id:
      "37870600919-q5t07ldunsittimqn01slogq5jt72dae.apps.googleusercontent.com",
    recaptcha_client: "6LeCKzEqAAAAADgIU5E1N5GcTH6IHAwLcrXW3epF",
  },

  amiktechnologies: {
    palette: {
      primary: {
        light: "#757ce8",
        main: "#007fff",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
    clusterSvgName: "amikchargeClusterSvg",
    faviconUrl: "amikchargefavicon.ico",
    termsAndConditionUrl: "https://amiktechnologies.com/terms-of-service",
    privacyPolicyUrl: "https://amiktechnologies.com/privacy-policy",
    playStoreUrl: "https://play.google.com/store/apps/details?id=com.in.amik",
    appStoreUrl: "https://apps.apple.com/in/app/amik-charge/id6482231255",
    title: "AmikTechnologies",
    domain: "amiktechnologies",
    provider: "AMIK Technologies",
    logoUrl: "logo/amikCharge.jpg",
    email: "info@amiktechnologies.com",
    phone: "8037805550",
    whatsappContact: "8130017399",
    registeredOffice:
      "A9-405, Casa Greens 1, Sector 16, Greater Noida West, 201318",
    regionalOffice:
      "Floor 24, Tower B, Bhutani Alphatum, Sector 90, Noida, Uttar Pradesh 201305",
    websiteUrl: "https://www.amiktechnologies.com",
    countryCode: "+91",
    google_client_id:
      "37870600919-q5t07ldunsittimqn01slogq5jt72dae.apps.googleusercontent.com",
    recaptcha_client: "6LeCKzEqAAAAADgIU5E1N5GcTH6IHAwLcrXW3epF",
  },
  
  techvoltnetwork: {
    palette: {
      primary: {
        light: "#44bf60",
        main: "#149631",
        dark: "#0e6b24",
        contrastText: "#fff",
      },
      secondary: {
        light: "#66cda1",
        main: "#2b8f6b",
        dark: "#1f624c",
        contrastText: "#000",
      },
    },    
    clusterSvgName: "chargemilesClusterSvg",
    faviconUrl: "techvoltNetworkFavicon.ico",
    termsAndConditionUrl: "https://techvoltnetwork.com/pg43/4/page/terms-and-conditions",
    privacyPolicyUrl: "https://techvoltnetwork.com/pg43/3/page/privacy-policy",
    playStoreUrl: "https://play.google.com/store/apps/details?id=com.in.technovolt&pli=1",
    appStoreUrl: "https://apps.apple.com/in/app/techvolt/id6736652565",
    title: "TechvoltNetwork",
    domain: "techvoltnetwork",
    provider: "Techvolt Network",
    logoUrl: "logo/techvoltNetwork.jpg",
    email: "techvoltnetwork@gmail.com",
    phone: "7002586661",
    whatsappContact: "7002586661",
    registeredOffice:
      "Office Tribe Coworking, Supreme Tower, Lobby- D, 3rd floor, Christian Basti, G.S.Road, Guwahati - 781005, Assam - India",
    regionalOffice:
      "Office Tribe Coworking, Supreme Tower, Lobby- D, 3rd floor, Christian Basti, G.S.Road, Guwahati - 781005, Assam - India",
    websiteUrl: "https://techvoltnetwork.com/",
    countryCode: "+91",
    google_client_id:
      "37870600919-q5t07ldunsittimqn01slogq5jt72dae.apps.googleusercontent.com",
    recaptcha_client: "6LeCKzEqAAAAADgIU5E1N5GcTH6IHAwLcrXW3epF",
  },

  avvtech: {
    palette: {
      primary: {
        light: "#4FC3F7",
        main: "#03A9F4",
        dark: "#0288D1",
        contrastText: "#fff",
      },
      secondary: {
        light: "#FF867F",
        main: "#f44336",
        dark: "#B71C1C",
        contrastText: "#fff",
      },
    },
    clusterSvgName: "amikchargeClusterSvg",
    faviconUrl: "amikchargefavicon.ico",
    termsAndConditionUrl: "https://www.avvtech.ca/terms-of-service",
    privacyPolicyUrl: "https://www.avvtech.ca/privacy-policy",
    playStoreUrl: "https://play.google.com/store/apps/details?id=com.avvtech.milesgo&pli=1",
    appStoreUrl: "https://apps.apple.com/us/app/milesgo/id6739272842",
    title: "AvvTech",
    domain: "avvtech",
    provider: "AvvTech",
    logoUrl: "https://static.joonsite.com/storage/4854/media/2411300853545403.png",
    email: "info@avvtech.ca",
    phone: "+1 (647)-248-1436",
    whatsappContact: "+1 (647)-248-1436",
    registeredOffice:
      "10 Bidgood Drive, Ajax, Ontario, Canada, L1Z0E2",
    regionalOffice:
      "10 Bidgood Drive, Ajax, Ontario, Canada, L1Z0E2",
    websiteUrl: "https://www.avvtech.ca/",
    countryCode: "+1",
    google_client_id:
      "37870600919-q5t07ldunsittimqn01slogq5jt72dae.apps.googleusercontent.com",
    recaptcha_client: "6Lf_DacqAAAAAKSnEmrjDJuCW0KJrMVkXH30JrOT",
  },
};
