import React, { useState, useMemo, useEffect } from "react";
import {
  Typography,
  Paper,
  Box,
  ToggleButton,
  useMediaQuery,
  SvgIcon,
  FormControlLabel,
  Switch,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Constant from "../../util/constants";
import { MHidden, MuiDialog } from "../@material-extend";
import { StyledFab } from "../common/Styled";
import {
  socketTypeList,
  chargePointSpeedList,
  vehicleSegmentList,
  networkFilterList,
} from "../../util/Display";
import { ReactComponent as FilterSVG } from "../../image/app_icon_filter.svg";
import "./Filter.css";
import { useDomainConfig } from "../../hooks/useDomainConfig";

const styles = {
  locationFilterModal: {
    width: 400,
    boxShadow: 24,
    p: 4,
    borderRadius: "25px",
  },
  locationFilterModalContent: {
    mt: 2,
    flexGrow: 1,
    overflow: "auto",
  },
  routePlannerGo: {
    "&:hover": {
      backgroundColor: "#01B763",
    },
  },
  font: {
    fontSize: ".6em",
    fontWeight: 600,
    color: "#000",
  },
  locationFilterFab: {
    bottom: "calc(48px + 124px)",
  },
  grid: {
    gridTemplateColumns: "repeat(4 minmax(0, 1fr))",
    rowGap: "1rem",
  },
};

const { action, label, domainProviderList } = Constant;

export default function LocationFilters({
  onFilter,
  mapFilter,
  loading,
  areNetworkFiltersFetched,
  networkFiltersArr,
}) {
  const theme = useTheme();
  const domainConfig = useDomainConfig();
  const [open, setOpen] = useState(false);
  const belowMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selected, setSelected] = useState(mapFilter);
  const socketTypes = useMemo(() => socketTypeList, []);
  const chargePointSpeed = useMemo(() => chargePointSpeedList, []);
  const networkFilters = useMemo(() => networkFiltersArr, [networkFiltersArr]);
  const filteredNetworkFilters = useMemo(() => {
    if (!areNetworkFiltersFetched) return [];
    return networkFiltersArr.filter((network) => {
      if (domainConfig.domain === "avvtech") {
        return network.countryCode === "+1"; // Include networks with country code +1
      }
      return network.countryCode === "+91"; // Include networks with country code +91
    });
  }, [networkFiltersArr, domainConfig, areNetworkFiltersFetched]);
  const segmentList = useMemo(() => vehicleSegmentList, []);
  const [clearFiltersSwitch, setClearFiltersSwitch] = useState(true);
  //  console.log(mapFilter)
  const handleEventHandler = (actionType) => (e) => {
    const target = e.currentTarget;

    switch (actionType) {
      case action.MODAL_OPEN:
        setOpen(!0);
        break;
      case action.MODAL_CLOSE:
        setOpen(!1);
        break;
      case action.TOGGLE_BUTTON:
        let updatedSelection = {
          ...selected,
          [target.value]: !selected[target.value],
        };
        setSelected({ ...updatedSelection });
        // console.log(updatedSelection)
        break;
      case action.SUBMIT:
        onFilter(selected);
        // console.log(selected)
        break;
      default:
        console.log("no action taken");
        break;
    }
  };

  useEffect(() => {
    setSelected(mapFilter);
    // console.log(mapFilter)
  }, [mapFilter]);

  useEffect(() => {
    if (selected) {
      const isAnyFilterSelected = Object.entries(selected).some(
        ([key, value]) => {
          if (
            [
              "segment2Wheeler",
              "segment3Wheeler",
              "segment4Wheeler",
              "segmentSwipe",
            ].includes(key)
          )
            return false;

          return value === true;
        }
      );
      if (isAnyFilterSelected) setClearFiltersSwitch(false);
    }
  }, [selected]);

  useEffect(() => {
    if (!loading) {
      setOpen(!1);
    }
  }, [loading]);

  const submitFilterHandler = (e) => {
    return handleEventHandler(action.SUBMIT)(e);
  };
  return (
    <div className="location-filter">
      <MHidden width="mdDown">
        <div
          component="div"
          className=" location-filter--paper-button Map-action-button desktop"
          onClick={handleEventHandler(action.MODAL_OPEN)}
        >
          <SvgIcon component={FilterSVG} inheritViewBox />
        </div>
      </MHidden>

      <MHidden width="mdUp">
        <Paper component="div" className="mobile">
          <StyledFab
            sx={styles.locationFilterFab}
            aria-label="location filter"
            onClick={handleEventHandler(action.MODAL_OPEN)}
          >
            <SvgIcon component={FilterSVG} inheritViewBox />
          </StyledFab>
        </Paper>
      </MHidden>

      <MuiDialog
        open={open}
        onClose={handleEventHandler(action.MODAL_CLOSE)}
        maxWidth={"sm"}
        onOk={submitFilterHandler}
        loading={loading}
        title={label.LOCATION_FILTERS}
      >
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <FormControlLabel
            control={
              <Switch
                checked={clearFiltersSwitch}
                onChange={() => {
                  const valueToUpdate = !clearFiltersSwitch;
                  if (valueToUpdate) {
                    const updatedSelectedValues = Object.entries(
                      selected
                    ).reduce((updatedSelectedValues, [key, value]) => {
                      if (
                        ![
                          "segment2Wheeler",
                          "segment3Wheeler",
                          "segment4Wheeler",
                          "segmentSwipe",
                        ].includes(key) &&
                        value === true
                      ) {
                        updatedSelectedValues = {
                          ...updatedSelectedValues,
                          [key]: !value,
                        };
                        return updatedSelectedValues;
                      }
                      updatedSelectedValues = {
                        ...updatedSelectedValues,
                        [key]: value,
                      };
                      return updatedSelectedValues;
                    }, {});
                    setSelected(updatedSelectedValues);
                  }
                  setClearFiltersSwitch(valueToUpdate);
                }}
              />
            }
            label="Clear Filters"
            labelPlacement="start"
          />
        </Stack>
        {belowMdScreen && (
          <Box className={`location-filter--filters location-filter--sockets`}>
            <Typography variant="subtitle1" component="div" gutterBottom>
              {label.VEHICLE_SEGMENT}
            </Typography>
            <div
              className={`location-filter--socket-types ${
                belowMdScreen && "mobile"
              }`}
            >
              {segmentList.map((segment, index) => (
                <div className="socket" key={`segment-${index}-${segment.id}`}>
                  <ToggleButton
                    value={segment.value}
                    selected={selected[segment.value]}
                    data-toggle-type="connector"
                    onChange={handleEventHandler(action.TOGGLE_BUTTON)}
                    className={selected[segment.value] ? "socket-toggle" : ""}
                  >
                    <img
                      src={
                        selected[segment.value]
                          ? segment.src
                          : segment.disableSrc
                      }
                      alt=""
                      height={32}
                      width={48}
                    />
                  </ToggleButton>
                  <Typography
                    variant="caption"
                    component="div"
                    sx={styles.font}
                    className="text-center"
                  >
                    {segment.type}
                  </Typography>
                </div>
              ))}
            </div>
          </Box>
        )}
        <Box className={`location-filter--filters location-filter--sockets`}>
          <Typography variant="subtitle1" component="div" gutterBottom>
            {label.SOCKET_TYPES}
          </Typography>
          <div
            className={`location-filter--socket-types ${
              belowMdScreen && "mobile"
            }`}
          >
            {socketTypes.map((socket, index) => (
              <div className="socket" key={`socket-${index}-${socket.id}`}>
                <ToggleButton
                  value={socket.value}
                  selected={selected[socket.value]}
                  data-toggle-type="connector"
                  onChange={handleEventHandler(action.TOGGLE_BUTTON)}
                  className={selected[socket.value] ? "socket-toggle" : ""}
                >
                  <span className="location-filter-socket-type-icon">
                    {socket.src}
                  </span>
                </ToggleButton>
                <Typography
                  variant="caption"
                  component="div"
                  sx={styles.font}
                  className="text-center"
                >
                  {socket.type}
                </Typography>
              </div>
            ))}
          </div>
        </Box>
        {/* <Box className="location-filter--filters location-filter--networks">
          <Typography variant="subtitle1" component="div" gutterBottom>
            {label.CHARGE_POINT_SPEEDS}
          </Typography>
          <div
            className={`location-filter--speed ${belowMdScreen && "mobile"}`}
          >
            {chargePointSpeed.map((speed, index) => (
              <div className="speed" key={`speed-${index}-${speed.id}`}>
                <ToggleButton
                  value={speed.value}
                  selected={selected[speed.value]}
                  data-toggle-type="power"
                  onChange={handleEventHandler(action.TOGGLE_BUTTON)}
                  className={selected[speed.value] ? "socket-toggle" : ""}
                >
                  <img src={speed.src} alt="" height={38} width={48} />
                </ToggleButton>
                <Typography
                  variant="caption"
                  component="div"
                  sx={styles.font}
                  className="text-center"
                >
                  {speed.type}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  className="text-center"
                  sx={{ fontSize: "0.6em", lineHeight: "8px" }}
                  gutterBottom
                >
                  {speed.kw}
                </Typography>
              </div>
            ))}
          </div>
        </Box> */}
        <Box className="location-filter--filters location-filter--charge-point-speeds">
          <Typography variant="subtitle1" component="div" gutterBottom>
            {label.NETWORKS}
          </Typography>
          {areNetworkFiltersFetched && (
            <div
              className={`location-filter--network  ${
                belowMdScreen && "mobile"
              }`}
            >
              {filteredNetworkFilters.map((network, index) => (
                <div
                  className="network"
                  key={`networkFilter-${index}-${network.id}`}
                >
                  <ToggleButton
                    value={network.name}
                    selected={selected[network.name]}
                    data-toggle-type="power"
                    onChange={handleEventHandler(action.TOGGLE_BUTTON)}
                    className={selected[network.name] ? "network-toggle" : ""}
                  >
                    <img src={network.icon} alt="" height={43} width={48} />
                  </ToggleButton>
                  <Typography
                    variant="caption"
                    component="div"
                    sx={styles.font}
                    className="text-center"
                  >
                    {network.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    className="text-center"
                    sx={{ fontSize: "0.6em", lineHeight: "8px" }}
                    gutterBottom
                  >
                    {network.name}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </Box>
      </MuiDialog>
    </div>
  );
}
