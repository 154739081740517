import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link as RouterLink } from "react-router-dom";
import axios from "../../../util/axios";
import OTPVerification from "./OTPVerification";
import { useDomainConfig } from "../../../hooks/useDomainConfig";

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [registrationDone, setRegistrationDone] = useState(false);
  const [openOtpVerify, setOpenOtpVerify] = useState(false);
  const [contactType, setContactType] = useState("");
  const [resendOtpPayloadObj, setResendOtpPayloadObj] = useState({});
  const phoneNoRegex = /^\d{10}$/i;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
  const domainConfig = useDomainConfig();
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Name required"),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    contact: Yup.string()
      .test(
        "phone-or-email",
        "Please enter a valid phone number or email address",
        (value) => {
          if (phoneNoRegex.test(value) || emailRegex.test(value)) return true;
          return false;
        }
      )
      .required("Either email or phone no. is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      contact: "",
      password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (registrationData, { setSubmitting, resetForm }) => {
      setSuccessMessage("");
      setError("");

      (async function () {
        try {
          const registerHelper = async () => {
            let registrationPayload = {
              name: registrationData.name,
              type: emailRegex.test(registrationData.contact)
                ? "email"
                : "phone",
              password: registrationData.password,
              domain: domainConfig.domain,
            };
            if (registrationPayload.type === "email")
              registrationPayload.email = registrationData.contact;
            else {
              registrationPayload.phone = registrationData.contact;
              registrationPayload.countryCode = domainConfig.countryCode;
            }
            const response = await axios(
              `${process.env.REACT_APP_API_END_POINT}auth/v2/register`,
              {
                method: "post",
                data: registrationPayload,
              }
            );
            const registerHelperResp = response.data;
            setContactType(registrationPayload.type);
            setResendOtpPayloadObj(registrationPayload);
            if (registerHelperResp && registerHelperResp.success) {
              if (
                registrationPayload.type === "phone" ||
                registrationPayload.type === "email"
              ) {
                setOpenOtpVerify(true);
                setSubmitting(false);
              } else {
                setSubmitting(false);
                resetForm();
                setSuccessMessage(registerHelperResp.message);
                setRegistrationDone(true);
              }
              // formik.values = initialFormValues;
            } else {
              setSubmitting(false);
              setError(registerHelperResp.message);
            }
          };
          await registerHelper();
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setSubmitting(false);
            setError(error.response.data.message);
          }
        }
      })();
      //navigate('/dashboard', { replace: true });
    },
  });

  const resendOtpFunc = async (phone) => {
    return new Promise(async (res, rej) => {
      try {
        const response = await axios(
          `${process.env.REACT_APP_API_END_POINT}auth/v2/register-resend-otp`,
          {
            method: "post",
            data: resendOtpPayloadObj,
          }
        );

        const registerHelperResp = response.data;
        if (registerHelperResp && registerHelperResp.success) {
          res("resend success");
        }
      } catch (error) {
        rej(error.response.data.message);
      }
    });
  };

  const submitOtp = (otp) => {
    return new Promise(async (res, rej) => {
      try {
        let otpPayload;
        if(contactType === "email") {
          otpPayload = {
            email: formik.values.contact,
            domain: domainConfig.domain,
            phoneOtp: otp.Otp,
            type: "email",
          }
        } else if(contactType === "phone") {
          otpPayload = {
            domain: domainConfig.domain,
            phone: formik.values.contact,
            countryCode: domainConfig.countryCode,
            phoneOtp: otp.Otp,
            type: "phone",
          }
        }
        const url = `${process.env.REACT_APP_API_END_POINT}auth/v2/register-verify-otp`;
        const response = await axios(url, {
          method: "post",
          data: otpPayload,
        });
        res(response.data);
      } catch (error) {
        rej(error.response.data.message);
      }
    });
  };
  const onSuccessfullVerify = () => {
    resetForm();
    setSuccessMessage("Registration successful");
    setRegistrationDone(true);
  };
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
    values,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <div style={{ color: "red" }}>{error}</div>
      <div style={{ color: "green" }}>{successMessage}</div>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {!registrationDone && (
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Name"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Stack>

            <TextField
              fullWidth
              autoComplete="username"
              type="text"
              label="Email address / Phone number"
              {...getFieldProps("contact")}
              error={Boolean(touched.contact && errors.contact)}
              helperText={touched.contact && errors.contact}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Register
            </LoadingButton>
          </Stack>
        )}
        {registrationDone && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <Link component={RouterLink} variant="subtitle2" to="/Login">
              Back to login
            </Link>
          </Stack>
        )}
      </Form>
      {openOtpVerify && (
        <OTPVerification
          contactType={contactType}
          contact={values.contact}
          onClose={() => setOpenOtpVerify(false)}
          open={openOtpVerify}
          submitOtpFunc={submitOtp}
          handleSuccess={onSuccessfullVerify}
          countryCode={
            contactType === "phone" ? domainConfig.countryCode : ""
          }
          resendOtp={resendOtpFunc}
        />
      )}
    </FormikProvider>
  );
}
